
import { defineComponent, getCurrentInstance, reactive, ref, watch, toRefs, computed } from 'vue';
import { IMAGE_UPLOAD } from '@/shared/constant/file';
import { createApp, createManApp, engineCreateApp } from '@/api/app';
import { SuccessResponse } from '@/types/response';
import { associatedList, appTemplateTypes, mainAppTemplateTypes } from '../utils/app-data-utils';
import { userProjectList } from '@/layout/messageCenter/user-info';
import { getShowBool } from '@/utils/permission-show-module';
import ServiceName from '@/views/service-management/components/ServiceName.vue';
import { getAllTags } from '@/api/settings/tags';
import { getClassificationList } from '@/api/settings/classification';
import { deleteBlankArray, sortMap } from '@/views/service-management/business-service/utils/service-data-utils';

interface StateInterface {
  createDialogVisible: boolean;
  appInfo: {
    name: string;
    templateType: number | null;
    description: string;
    devType: number;
    remark: string;
    thumbnail: string;
    services: any[];
    showName: string;
  };
  imageUrl: string;
  statusLabel: string;
}

export default defineComponent({
  name: 'ApplicationManagement',
  components: {
    ServiceName,
  },
  props: {
    createDialogVisibleFun: {
      type: Boolean,
    },
    appType: {
      type: String,
      default: 'micro',
    },
  },
  setup(props, ctx) {
    const instance = getCurrentInstance();
    const submitting = ref(false);
    const serviceCascaderProps = ref({
      multiple: true,
    } as any);

    const appLabel = computed(() => {
      if (props.appType === 'main') {
        return '主应用';
      }
      return '微模块';
    });

    const projectId = localStorage.getItem('projectId');

    const state: StateInterface = reactive({
      createDialogVisible: props.createDialogVisibleFun,
      appInfo: {
        name: '',
        templateType: null,
        description: '',
        devType: 0,
        remark: '',
        thumbnail: '',
        services: [],
        showName: '',
      },
      imageUrl: '',
      statusLabel: '',
    });
    watch(
      () => props.createDialogVisibleFun,
      () => {
        state.createDialogVisible = props.createDialogVisibleFun;
      },
    );
    const englishName = ref(null as any);
    const descriptionName = ref(null as any);
    const appFormRef = ref(null as any);

    const rules = {
      templateType: [{ required: true, message: '请选择代码模版', trigger: 'change' }],
      description: [
        { required: true, message: '请输入模块中文名称', trigger: 'blur' },
        {
          min: 1,
          max: 20,
          message: '模块中文名称长度在1到20个字符之间',
          trigger: 'blur',
        },
      ],
      name: [
        { required: true, message: '请输入模块英文名称', trigger: 'blur' },
        {
          min: 3,
          max: 16,
          message: '模块英文名称长度在3到16个字符之间',
          trigger: 'blur',
        },
        {
          pattern: /^[a-z]+$/g,
          message: '该模块英文名仅可使用小写字母，请重新输入',
          trigger: 'blur',
        },
      ],
    };
    const handleClose = () => {
      ctx.emit('DialogVisibleFun', false);
    };
    setInterval(() => (state.statusLabel = new Date().toDateString()), 2000);
    const beforeUpload = (file: { size: number }) => {
      if (file.size > 1024 ** 2 * 3) {
        (instance as any).proxy.$message({
          type: 'warning',
          message: '上传图片大小不能超过3M',
        });
        return false;
      }
    };
    const logoUploadSuccess = (res: SuccessResponse<any>, file: { raw: any }) => {
      if (res.code === 0 && res.data?.fileKey) {
        state.appInfo.thumbnail = res.data.fileKey;
        state.imageUrl = URL.createObjectURL(file.raw);
      } else {
        (instance as any).proxy.$message({
          type: 'error',
          message: '上传失败，请重新上传！',
        });
      }
    };

    const nodeChange = (nodes: any) => {
      const checkNode: any = {};
      for (const node of nodes) {
        checkNode[node[0]] = node;
      }
      state.appInfo.services = Object.values(checkNode);
    };

    const findService = (arrs: any) => {
      const mapData: any = {};
      const result: any = [];
      associatedList.value.forEach((x: any) => {
        mapData[x.value] = x.children;
      });
      const arr = Object.values(arrs);
      arr.forEach((xs: any) => {
        const x = Object.values(xs);
        // eslint-disable-next-line
        // @ts-ignore
        const versions = mapData[x[0]];
        versions.forEach((y: any) => {
          if (y.value === x[1]) {
            result.push({
              serviceName: x[0],
              serviceVersion: x[1],
              serviceVersionType: y.versionType,
            });
          }
        });
      });
      return result;
    };

    // 获取所有标签
    const tags = ref([] as any);
    const tagValue: any = ref([]);

    const getTags = async () => {
      const { data } = await getAllTags();
      tags.value.push(...(data || []));
    };

    getTags();

    // 获取所有分类信息
    const classifications: any = ref([]);
    const classificationValue: any = ref([]);
    const getClassifications = async () => {
      const { data } = await getClassificationList();
      const item = { children: data };
      deleteBlankArray(item);
      classifications.value = item.children || [];
      console.log(sortMap.value);
    };

    getClassifications();

    const submitAppCreate = async (formEl: any) => {
      await formEl.validate(async (valid: any, fields: any) => {
        if (valid) {
          const submitDataCopy = {
            ...state.appInfo,
            tag: tagValue.value?.join(',') || '',
            classification: classificationValue.value?.toString() || '',
          };
          submitDataCopy.services = findService(state.appInfo.services);
          submitting.value = true;
          try {
            if (props.appType === 'micro') {
              const {
                code,
                data: { id },
              } = await createApp(submitDataCopy);
              if (code === 0) {
                (instance as any).proxy.$message({
                  type: 'success',
                  message: '模块新建成功',
                });
                ctx.emit('DialogVisibleFun', false);
                ctx.emit('showFun', true);
                // 创建应用编排信息
                if (state.appInfo.devType === 1) {
                  await engineCreateApp({
                    openId: 'citybase001',
                    appId: `${projectId}-${id}`,
                    name: state.appInfo.name,
                    desc: state.appInfo.description,
                    imgUrl: state.appInfo.thumbnail,
                    type: state.appInfo.templateType === 0 ? 1 : 2,
                    theme: '',
                  });
                }
              }
            } else {
              const { code } = await createManApp(submitDataCopy);
              if (code === 0) {
                (instance as any).proxy.$message({
                  type: 'success',
                  message: '模块新建成功',
                });
                ctx.emit('DialogVisibleFun', false);
                ctx.emit('showFun', true);
              }
            }
            submitting.value = false;
          } catch (e) {
            console.log(e);
            submitting.value = false;
          } finally {
            state.imageUrl = '';
            state.appInfo = {
              name: '',
              templateType: null,
              description: '',
              devType: 0,
              remark: '',
              thumbnail: '',
              services: [],
              showName: '',
            };
            tagValue.value = [];
            classificationValue.value = '';
          }
        } else {
          console.log('error submit!', fields);
        }
      });
    };

    const closeAppCreate = async () => {
      state.createDialogVisible = false;
      state.imageUrl = '';
      appFormRef.value.resetFields();
      state.appInfo = {
        name: '',
        templateType: null,
        description: '',
        devType: 0,
        remark: '',
        thumbnail: '',
        services: [],
        showName: '',
      };
      tagValue.value = [];
      classificationValue.value = '';
      handleClose();
    };

    const getTemplateTypes = () => (props.appType === 'main' ? mainAppTemplateTypes : appTemplateTypes);
    return {
      IMAGE_UPLOAD,
      ...toRefs(state),
      rules,
      associatedList,
      beforeUpload,
      logoUploadSuccess,
      submitAppCreate,
      handleClose,
      closeAppCreate,
      englishName,
      descriptionName,
      appFormRef,
      userProjectList,
      getShowBool,
      submitting,
      serviceCascaderProps,
      nodeChange,
      tags,
      tagValue,
      classificationValue,
      classifications,
      appLabel,
      getTemplateTypes,
    };
  },
});
