
import ApplicationHeader from '../components/ApplicationHeader.vue';
import { defineComponent, reactive, ref, toRefs } from 'vue';
import ApplicationDialog from './ApplicationDialog.vue';
import { associatedList, getServiceAssociated } from '../utils/app-data-utils';
import { userProjectList } from '@/layout/messageCenter/user-info';
import { getShowBool } from '@/utils/permission-show-module';
import { getAllTags } from '@/api/settings/tags';
import AppList from './app-list/Index.vue';

interface StateInterface {
  applicationList: any[];
  total: number;
  loading: boolean;
  createDialogVisible: boolean;
  statusLabel: string;
}

export default defineComponent({
  name: 'ApplicationManagement',
  components: {
    ApplicationHeader,
    ApplicationDialog,
    AppList,
  },
  props: {
    appType: {
      type: String,
      default: 'micro',
    },
  },
  setup(props) {
    console.log(111, props.appType);
    const $appList = ref<InstanceType<typeof AppList>>();
    const submitting = ref(false);
    const serviceCascaderProps = ref({
      multiple: true,
    } as any);

    const searchProps = reactive({
      keyword: '',
      page: 1,
      pageSize: 10,
    });

    const state: StateInterface = reactive({
      applicationList: [],
      total: 0,
      loading: false,
      createDialogVisible: false,
      statusLabel: '',
    });
    const englishName = ref(null as any);
    const descriptionName = ref(null as any);
    const form = ref(null as any);

    const DialogVisibleFunBoole = (value: boolean) => {
      state.createDialogVisible = value;
    };

    setInterval(() => (state.statusLabel = new Date().toDateString()), 2000);

    const openCreateDialog = () => {
      getServiceAssociated();
      state.createDialogVisible = true;
    };

    const handleCreated = () => {
      // eslint-disable-next-line no-unused-expressions
      $appList.value?.refresh();
    };
    // 获取所有标签
    const tags = ref([] as any);
    const tagValue: any = ref([]);

    const getTags = async () => {
      const { data } = await getAllTags();
      tags.value.push(...(data || []));
    };

    getTags();

    const filterApps = (keyword: string) => {
      searchProps.keyword = keyword;
    };

    return {
      searchProps,
      ...toRefs(state),
      associatedList,
      openCreateDialog,
      DialogVisibleFunBoole,
      filterApps,
      englishName,
      descriptionName,
      form,
      userProjectList,
      getShowBool,
      submitting,
      handleCreated,
      serviceCascaderProps,
      tags,
      tagValue,
    };
  },
});
