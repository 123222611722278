
import { defineComponent, reactive, ref } from 'vue';
import { IMAGE_UPLOAD } from '@/shared/constant/file';
import { userProjectList } from '@/layout/messageCenter/user-info';
import { getShowBool } from '@/utils/permission-show-module';

interface StateInterface {
  applicationList: any[];
  total: number;
  loading: boolean;
  createDialogVisible: boolean;
  appInfo: {
    name: string;
    description: string;
    remark: string;
    thumbnail: string;
    services: any[];
    showName: string;
  };
  imageUrl: string;
  statusLabel: string;
}

export default defineComponent({
  name: 'ApplicationManagement',
  setup(props, ctx) {
    const submitting = ref(false);
    const serviceCascaderProps = ref({
      multiple: true,
    } as any);

    const searchProps = reactive({
      keyword: '',
    });

    const filterApps = () => {
      ctx.emit('serchFun', searchProps.keyword);
    };
    const englishName = ref(null as any);
    const descriptionName = ref(null as any);
    const form = ref(null as any);
    const openCreateDialog = () => {
      ctx.emit('openFun', true);
    };

    return {
      IMAGE_UPLOAD,
      searchProps,
      englishName,
      descriptionName,
      form,
      filterApps,
      openCreateDialog,
      userProjectList,
      submitting,
      serviceCascaderProps,
      getShowBool,
    };
  },
});
