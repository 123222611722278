import { resolveComponent as _resolveComponent, createVNode as _createVNode, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_ApplicationHeader = _resolveComponent("ApplicationHeader")!
  const _component_AppList = _resolveComponent("AppList")!
  const _component_ApplicationDialog = _resolveComponent("ApplicationDialog")!

  return (_openBlock(), _createElementBlock(_Fragment, null, [
    _createVNode(_component_ApplicationHeader, {
      onOpenFun: _ctx.openCreateDialog,
      onSerchFun: _ctx.filterApps
    }, null, 8, ["onOpenFun", "onSerchFun"]),
    _createVNode(_component_AppList, {
      appType: _ctx.appType,
      keyword: _ctx.searchProps.keyword,
      create: _ctx.openCreateDialog,
      ref: "appList"
    }, null, 8, ["appType", "keyword", "create"]),
    _createVNode(_component_ApplicationDialog, {
      appType: _ctx.appType,
      onCreated: _ctx.handleCreated,
      createDialogVisibleFun: _ctx.createDialogVisible,
      onDialogVisibleFun: _ctx.DialogVisibleFunBoole
    }, null, 8, ["appType", "onCreated", "createDialogVisibleFun", "onDialogVisibleFun"])
  ], 64))
}